
  import Vue from "vue";
  import {MODAL_IDS, MODALS_LOADED} from "@/utils/modal";

  export default Vue.extend({
    name: "pwa-followers-modal",
    props: {
      otherUserId: {
        type: Number,
        default: null,
        required: true
      },
      otherUsername: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        loadContent: false,
      };
    },
    mounted(): void {
      MODALS_LOADED[this.modalId] = true;
    },
    components: {
      PwaFollowers: () => import("@/components/PwaFollowers.vue"),
    },
    computed: {
      modalId(): string {
        return MODAL_IDS.FOLLOWERS;
      },
    },
    methods: {
      onShow() {
        if (!this.loadContent) {
          this.loadContent = true;
        }
      },
    },
  });
