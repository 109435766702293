
  import Vue from 'vue';
  import PwaUserProfileHeader from "@/components/user/PwaUserProfileHeader.vue";
  import PwaLastGamesList from "@/components/profile/PwaLastGamesList.vue";
  import {ROUTES} from "@/router/routes";
  import {CHAT_TYPES, LastChatMessage} from "@/services/chat";
  import {AuthActionTypes} from "@/store/modules/auth/actions";
  import {MODAL_IDS} from "@/utils/modal";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaUserLevel from '@/components/user/PwaUserLevel.vue';
  import {EVENTS} from "@/utils/events";

  const allGamesData = {alias: 'all', related_game_id: 0};
  const COMPETITION_TAB_INDEX = {
    WAGERS: 0,
    INDIVIDUAL: 1,
  };

  export default Vue.extend({
    name: "Profile",
    mixins: [pageMixin],
    components: {
      PwaUserProfileHeader,
      PwaLastGamesList,
      PwaPrice,
      PwaUserLevel,
      PwaCompetitionCard: () => import("@/components/competitions/PwaCompetitionCard.vue"),
      PwaChatContainer: () => import("@/components/chat/PwaChatContainer.vue"),
      PwaWebAdBillboardBanner: () => import('@/components/ads/PwaWebAdBillboardBanner.vue'),
      PwaWebAdHalfPageBanner: () => import('@/components/ads/PwaWebAdHalfPageBanner.vue'),
      PwaCompetitionIndividualList: () => import("@/components/competitions/PwaCompetitionIndividualList.vue"),
    },
    data() {
      return {
        userData: {} as any,
        activeCompetitions: [],
        historicalCompetitions: [],
        historicalIndividualCompetitions: [],
        userGames: [],
        chatData: {} as any,
        selectedChatData: null as LastChatMessage,
        following: null,
        blocked: null,
        userGamesStats: {lost: 0, won: 0, total_prizes: 0, win_rate: 0},
        tabs: {current: 0, historical: 1},
        gameSelected: allGamesData,
        selectGameSelected: allGamesData.alias,
        competitionTabIndexSelected: COMPETITION_TAB_INDEX.WAGERS,
      }
    },
    mounted(): void {
      this.getUserProfilePage();
      this.$root.$on(EVENTS.USER_BLOCK_MANAGEMENT_UPDATED, this.getUserProfilePage);
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.USER_BLOCK_MANAGEMENT_UPDATED, this.getUserProfilePage);
    },
    methods: {
      async getUserProfilePage() {
        this.showLoader(true);
        try {
          let {data} = await this.$http.page.getUserProfilePage(this.username);
          this.userData = data.data.user;
          this.userGames = data.data.user_games || [];
          this.activeCompetitions = data.data.active_competitions;
          this.historicalCompetitions = data.data.historical_competitions;
          this.historicalIndividualCompetitions = data.data.historical_individual_competitions || [];
          this.userGamesStats = data.data.user_games_stats || {};
          this.following = data.data.following;
          this.blocked = data.data.blocked_by_user;
          this.selectCompetitionTabIndex();
          if (this.isOwnProfile) {
            if (this.chatLastMessages.length > 0 && !this.selectedChatData) {
              this.onSelectedChatUserClick(this.chatLastMessages[0]);
            }
          } else {
            this.chatData = data.data.chat_data;
          }
        } catch (e) {
          // Show error
        } finally {
          this.showLoader(false);
        }
      },
      onGameClick(game: any) {
        const urlName = game.minigame == '1' ? ROUTES.minigame.name : ROUTES.arena.name;
        this.$router.push({name: urlName, params: {lang: this.routeLang, gameAlias: game.alias}});
      },
      onAddNewGameClick() {
        this.showModalById(MODAL_IDS.ADD_GAME);
      },
      async onPostUserAvatar(avatar: string) {
        try {
          this.showLoader(true);
          await this.$http.user.postUserAvatar(this.userId, avatar);
          this.$store.dispatch(AuthActionTypes.UPDATE_USER_DATA, false);
          this.getUserProfilePage();
        } catch (e) {
          this.showLoader(false);
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
      async onSelectedChatUserClick(selectedChatData: LastChatMessage) {
        if (!this.selectedChatData || this.selectedChatData.userId !== selectedChatData.userId) {
          this.selectedChatData = selectedChatData;
          await this.getUserChatData();
        }
      },
      async getUserChatData() {
        try {
          this.showLoader(true);
          const {data} = await this.$http.user.getChatData(CHAT_TYPES.USERS, {username: this.selectedChatData.userName});
          this.chatData = data.data;
        } catch (e) {
          // TODO
        } finally {
          this.showLoader(false);
        }
      },
      async onClickFollow() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.userData.id && !this.isOwnProfile) {
            try {
              this.showLoader(true);
              await this.$http.user.followUser(this.userData.id);
              await this.getUserProfilePage();
            } catch (e) {
              this.showToastError(this.$t('139'), this.$t('140'));
              this.showLoader(false);
            }
          }
        }
      },
      async onClickChat() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else {
          const username = this.userData.username;
          this.$router.push({name: ROUTES.messagesUser.name, params: {lang: this.routeLang, username}});
        }
      },
      async onClickUnFollow() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.userData.id && !this.isOwnProfile) {
            try {
              this.showLoader(true);
              await this.$http.user.unfollowUser(this.userData.id);
              await this.getUserProfilePage();
            } catch (e) {
              this.showToastError(this.$t('139'), this.$t('140'));
              this.showLoader(false);
            }
          }
        }
      },
      onClickBlock() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          this.showModalById(MODAL_IDS.USER_BLOCK_MANAGEMENT, {username: this.userData.username});
        }
      },
      onSeeMoreMessagesClick() {
        this.$router.push({name: ROUTES.messages.name, params: {lang: this.routeLang}});
      },
      async onGameChange(gameIndex: number|string) {
        if (gameIndex === allGamesData.alias) {
          this.gameSelected = allGamesData;
        } else {
          this.gameSelected = this.userGames[(gameIndex as number)];
        }

        this.showLoader(true);
        try {
          const { data } = await this.$http.page.getUserProfileGamePage(this.username, this.gameSelected.alias);
          this.historicalCompetitions = data.data.historical_competitions;
          this.historicalIndividualCompetitions = data.data.historical_individual_competitions || [];
          this.userGamesStats = data.data.user_games_stats || {};
          this.selectCompetitionTabIndex();
        } catch (e) {
          // Show error
        } finally {
          this.showLoader(false);
        }
      },
      selectCompetitionTabIndex() {
        if (this.historicalCompetitions.length > 0) {
          this.competitionTabIndexSelected = COMPETITION_TAB_INDEX.WAGERS;
        } else if (this.historicalIndividualCompetitions.length > 0) {
          this.competitionTabIndexSelected = COMPETITION_TAB_INDEX.INDIVIDUAL;
        }
      },
    },
    computed: {
      username(): string {
        return this.$route.params.username;
      },
      isOwnProfile(): boolean {
        let ownProfile = false;
        if (this.userData) {
          ownProfile = this.userData.id == this.userId;
        }
        return ownProfile;
      },
      chatLastMessages(): Array<LastChatMessage> {
        const lastMessages = this.$store.state.user.chat.lastMessages || [];
        return lastMessages.slice(0, 5);
      },
      chatId(): string | null {
        return this.chatData.chat_id ? this.chatData.chat_id : null;
      },
      breadcrumb(): BreadcrumbItemType[] {
        return [
          BreadcrumbItems.lobby,
          {text: this.username},
        ];
      },
      selectedTab(): number {
        return (this.activeCompetitions && this.activeCompetitions.length > 0 && this.isOwnProfile) ? this.tabs.current : this.tabs.historical;
      }
    },
    watch: {
      username() {
        this.chatData = {};
        this.gameSelected = allGamesData;
        this.selectGameSelected = allGamesData.alias;
        this.selectedChatData = null;
        this.getUserProfilePage();
      },
      chatLastMessages() {
        if (this.isOwnProfile && this.chatLastMessages.length > 0 && !this.selectedChatData) {
          this.onSelectedChatUserClick(this.chatLastMessages[0]);
        }
      },
    }
  });
