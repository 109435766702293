
  import Vue from "vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import {ROUTES} from "@/router/routes";
  import {COMPETITION_STATUS, COMPETITION_TYPE_IDS} from "@/utils/competition";

  export default Vue.extend({
    name: "pwa-last-games-list",
    components: {
      PwaPrice,
    },
    props: {
      competitions: {
        type: Array,
        required: true,
      },
      relatedGameId: {
        type: [String,Number],
        required: true,
      },
      currentUserId: {
        type: Number,
        required: false,
      },
      currentUserName: {
        type: String,
        required: false,
      },
      limitBlock: {
        type: Number,
        required: false,
        default: 4,
      },
    },
    data() {
      return {
        competitionsList: [] as any[],
        hasMoreCompetitions: false,
        routes: ROUTES,
      }
    },
    mounted() {
      this.updateCompetitions(this.competitions, true);
    },
    methods: {
      goToCompetitionPage(competitionId: string): void {
        this.routerPush(ROUTES.competition.name, {competitionId});
      },
      getUserNamePath(username: string) {
        const routeLang = this.routeLang ? `/${this.routeLang}/` : '/';
        return username
          ? `${routeLang}${ROUTES.profile.path.replace(':username', encodeURIComponent(username))}`
          : '#';
      },
      goToUserProfile(username: string) {
        if (username) {
          this.routerPush(ROUTES.profile.name, {username});
        }
      },
      async onSeeMoreClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          this.showLoader(true);
          const {data} = await this.$http.user.getUserCompetitionsHistory(this.currentUserName, {
            limit: this.limitBlock,
            offset: this.competitionsList.length,
            type_id: COMPETITION_TYPE_IDS.WAGER,
            related_game_id: this.relatedGameId,
            status: COMPETITION_STATUS.FINISHED,
          });
          const competitions = data.data || [];
          this.updateCompetitions(competitions);
          this.showLoader(false);
        }
      },
      updateCompetitions(competitions: any[], forceReset = false): void {
        if (this.competitionsList.length > 0 && !forceReset) {
          this.competitionsList = [...this.competitionsList, ...competitions]
        } else {
          this.competitionsList = competitions;
        }

        this.hasMoreCompetitions = competitions.length >= this.limitBlock;
      },
    },
    watch: {
      competitions() {
        this.updateCompetitions(this.competitions, true);
      }
    }
  });
