import { openInstagramProfile, openTwitchChannel, openTwitterProfile, openYoutubeChannel } from '@/utils/app-launcher';

export const SOCIAL_NETWORKS = {
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  YOUTUBE: 'youtube',
  TWITTER: 'twitter',
  DISCORD: 'discord',
};

export interface SocialNetworkDataType {name: string, icon: string, propertyName:string, getUrl: (username : string) => string, openLink: (username : string) => void }

export const SOCIAL_NETWORKS_DATA: SocialNetworkDataType[] = [
  {
    name: 'X',
    icon: 'x',
    propertyName: SOCIAL_NETWORKS.TWITTER,
    getUrl: (username: string) => `https://twitter.com/${username}`,
    openLink: (username: string) => openTwitterProfile(username),
  },
  {
    name: 'Youtube',
    icon: 'youtube',
    propertyName: SOCIAL_NETWORKS.YOUTUBE,
    getUrl: (username: string) => `https://www.youtube.com/@${username}`,
    openLink: (username: string) =>openYoutubeChannel(username),
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    propertyName: SOCIAL_NETWORKS.INSTAGRAM,
    getUrl: (username: string) => `https://www.instagram.com/${username}`,
    openLink: (username: string) => openInstagramProfile(username),
  },
  {
    name: 'Twitch',
    icon: 'twitch',
    propertyName: SOCIAL_NETWORKS.DISCORD,
    getUrl: (username: string) => `https://www.twitch.tv/${username}`,
    openLink: (username: string) => openTwitchChannel(username),
  },
];
