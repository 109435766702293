
  import Vue, {PropType} from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {ROUTES} from "@/router/routes";
  import {MODAL_IDS} from "@/utils/modal";
  import PwaFollowersModal from "@/components/modals/PwaFollowersModal.vue";
  import {SOCIAL_NETWORKS_DATA, SocialNetworkDataType} from "@/utils/social-networks";
  import PwaReportUserModal from "@/components/modals/PwaReportUserModal.vue";

  export default Vue.extend({
    name: "pwa-user-profile-header",
    components: {
      PwaFollowersModal,
      PwaIcon,
      PwaInputFile: () => import("@/components/PwaInputFile.vue"),
    },
    data() {
      return {
        image: null as File,
      };
    },
    props: {
      userData: {
        type: Object,
        required: true,
      },
      onPostUserAvatar: {
        type: Function as PropType<(avatar: string) => void>,
        required: false,
      },
      onClickFollow: {
        type: Function as PropType<() => void>,
        required: false,
        default: () => {/** Nothing to do */
        },
      },
      onClickChat: {
        type: Function as PropType<() => void>,
        required: false,
        default: () => {/** Nothing to do */
        },
      },
      onClickUnFollow: {
        type: Function as PropType<() => void>,
        required: false,
        default: () => {/** Nothing to do */
        },
      },
      onClickBlock: {
        type: Function as PropType<() => void>,
        required: false,
        default: () => {/** Nothing to do */
        },
      },
      following: {
        type: Boolean,
        required: false,
        default: null,
      },
      blocked: {
        type: Boolean,
        required: false,
        default: null,
      },
    },
    methods: {
      onClickEditProfile() {
        this.$router.push({name: ROUTES.account.name, params: {lang: this.routeLang}});
      },
      onVerifyIdentityClick() {
        this.$router.push({name: ROUTES.verifyIdentity.name, params: {lang: this.routeLang}});
      },
      onUserProfileClick() {
        if (this.isDesktop && this.isOwnProfile) {
          const imageInput = this.$refs.imageInput as any;
          imageInput.chooseFile();
        }
      },
      onUserFollowersFollowingClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.isDesktop) {
            this.showModalById(MODAL_IDS.FOLLOWERS);
          } else {
            this.$router.push({
              name: ROUTES.followers.name,
              params: {lang: this.routeLang, username: this.userData.username, otherUsername: this.userData.username, otherUserId: this.userData.id}
            });
          }
        }
      },
      onClickChallengeUserClick() {
        this.createMatch({competitionProposal: {username: this.userData.username}});
      },
      showReportModal() {
        const message = this.$createElement(PwaReportUserModal, {
          props: {
            reportUserId: this.userData.id,
            reportUsername: this.userData.username,
          },
        });
        this.showModal({
          id: MODAL_IDS.MODAL_REPORT_USER,
          message,
          contentClass: {'hide-footer': true, 'is-desktop': this.isDesktop},
        });
      },
    },
    computed: {
      isOwnProfile(): boolean {
        let ownProfile = false;
        if (this.userData) {
          ownProfile = this.userData.id == this.userId;
        }
        return ownProfile;
      },
      socialNetworksData(): SocialNetworkDataType[] {
        return SOCIAL_NETWORKS_DATA.filter(({ propertyName }) => this.userData.userProfile && this.userData.userProfile[propertyName]);
      },
    },
  });
